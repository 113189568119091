import React from 'react'
import { graphql, Link } from 'gatsby';

import Layout from 'components/Layout'
import SEO from 'components/SEO';
import Content from 'components/Content';
import Hero from 'components/Hero';
import GridCard from 'components/GridCard';

import "styles/CasePageTemplate.scss";
import Projects from '../components/Projects';

const ProjectPage = ({ data, location }) => {
  const { post, posts, craftsmen } = data
  const { acf: { hero, title } } = post;
  const url = location.href ? location.href : '';


  const language = post.wpml_current_locale.slice(0, 2);

  const total = [].concat(posts.edges, craftsmen.edges);
  
  const sorted = total.sort(function(a,b) {
    var dateA = new Date(a.node.date), dateB = new Date(b.node.date);
    return dateB - dateA;
  });

  return (
    <Layout>
      <SEO title={post.title} lang={language} pathname={url} />
      <div className="project-overview-page">
        <Hero
          className="project-overview-hero"
          fluidImage={hero.image.localFile.childImageSharp.fluid}
          column={false}
        />

        {sorted.length > 0 && <Projects otherProjects={sorted} title={title} withButton={false} />}
      </div>
    </Layout>
  )
}

export default ProjectPage

export const query = graphql`
  query ProjectPageByID($wordpress_id: Int!, $language: String!) {
    posts: allWordpressPost(filter: { 
      status: { eq: "publish" } 
      categories: {elemMatch: {slug: {eq: "blog"}}}
      wpml_current_locale: { eq: $language }
    }) {
      edges {
        node {
          title
          path
          date
          acf {
            banner {
              localFile {
                childImageSharp {
                  fixed(quality: 100, width: 340, height: 318) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }

    craftsmen: allWordpressWpCraftsmen {
      edges {
        node {
          title
          path
          date
          acf {
            banner {
              localFile {
                childImageSharp {
                  fixed(quality: 100, width: 340, height: 318) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }

    post: wordpressPage(
      wordpress_id: { eq: $wordpress_id }
    ) {
      id
      wordpress_id
      title
      slug
      content
      wpml_current_locale
      acf {
        hero {
          description
          image {
            localFile {
              childImageSharp {
                fluid(quality: 99, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
        
        title
      }
    }
  }
`
